// Misc styles
// NOTE single-selector sections/partials go here
// --------------------

/**
 * "Radio" button styles (applied to buttton elements) in Block Guide
 */

.btn.active.focus, 
.btn.active:focus, 
.btn.focus, 
.btn:active.focus, 
.btn:active:focus, 
.btn:focus,
.btn:hover {
	outline: none;
	border-color: $brand-text-color-light;
}

.btnRadio {
	background-color: transparent;
	position: relative;
	height: 50px;
	margin: 5px;
	margin-left: 0;
	padding-left: 55px;

	&:before {
		content: "";
		width: 50px;
		height: 50px;
		border-radius: 25px;
		border: 1px solid $brand-text-color;
		position: absolute;
		left: 0;
		top: 0;
	}

	.btnRadio--title {
		display: block;
		text-align: left;
		font-size: 20px;
		font-weight: bold;
		line-height: 1;
	}


	&:active,  &.active ,
	&:active:hover, &:active:hover,
	&.active:hover, &.active:focus,
	&.active.focus {

	  .btnRadio--title:before {
  		content: "";
  		width: 24px;
  		height: 24px;
  		border-radius: 12px;
			background-color: $brand-primary;
  		position: absolute;
  		left: 12.5px;
  		top: 12.5px;
  	}
  }
}

.btn-group > .btn:last-child:not(:first-child).btnRound,
.btn-group > .dropdown-toggle:not(:first-child).btnRound {
	border-bottom-left-radius: 60px;
	border-top-left-radius: 60px;
}
.btn-group > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
    border-bottom-right-radius: 60px;
    border-top-right-radius: 60px;
}
.btnRound {
	border: 3px solid $brand-text-color;
	background-color: transparent;
	width: 120px;
	height: 120px;
	border-radius: 60px;
	border-bottom-left-radius: 60px;
	border-top-left-radius: 60px;
	margin-right: 20px;
	margin-bottom:  20px;
}

.btnRound--alt { /* very similar in aspect to btnRound, but they don't actually share properties */
	position: relative;
	display: table-cell;
	border: none;
	background-color: $brand-primary;
	color: $brand-accent;
	white-space: normal;
	word-wrap: wrap;
	text-transform: uppercase;
	font-family: $headings-font-family;
	line-height: 1;
	margin: 0 auto;
	text-align: center;
	vertical-align: middle;
	width: 154px;
	height: 154px;
	border-radius: 77px;
	border-bottom-left-radius: 77px;
	border-top-left-radius: 77px;
	font-size: 22px;
	font-weight: bold;
}
/**
 * feedback box (in Single Posts)
 */
.feedback {
	border-top: 1px solid $brand-grey;
	border-bottom: 1px solid $brand-grey;
	padding: 0 0 10px 0;
	margin-top: 20px;
	
	.icon-arrow {
		@extend .col-xs-1;
		float: left;
		display: inline-block;
	} 
	.btn-group {
		@extend .col-xs-12, .col-md-6;
		text-align: center;
		margin: 0 0 20px;
		.row {
			max-width: 300px;
			margin: 0 auto;
		}

		.btn-wrapper {
			@extend .col-xs-6;
		}
	}
	.btnRound {
		text-transform: uppercase;
		font-size: 32px;
		margin: 0 auto;

		&.btnYes {
			color: $brand-primary;
		}
		&.btnNo {
			color: $brand-accent;
		}
	}
	.feedback--contact {
		width: 100%;
		height: 100%;
		text-align: center;
		margin: 1.5em auto 1em;
	}
	.btn-contact {
		display: block;
		border: 2px solid $brand-text-color;
		background-color: transparent;
		color: $brand-text-color;
		width: 280px;
		margin: 0 auto;
		padding: 0.5em 1em;
	}
	.btn-contact:hover {
		border-color: $brand-white;
		background-color: $brand-text-color;
		color: $brand-white;
		text-decoration: none;
	}
	.feedback--text {
		text-align: center;
	}
	.feedback--success {
		overflow: auto;
		height: 100%;
	}
}
.feedback--title {
	font-size: 23px;
	text-transform: uppercase;
	color: $brand-grey;
	display: inline-block;
	width: auto;

	&:after {
		content:" ";
		width: 305px;
		max-width: 100%;
		height: 6px;
		background-color: $brand-primary;
		position: absolute;
		top: 0;
		left: 0;
		padding-right: 0;
	}
}
.feedback--subtitle {
	@extend .col-xs-11, .col-md-5;
	font-family: $headings-font-family;
	text-transform: uppercase;
	color: $brand-primary;
	font-size: 45px;
	line-height: 1.2;
	padding-left: 30px;

	@include mq($from: bigphone) {
		padding-left: 0;
	}
}

.note {
	margin: 60px 0 20px;

	.note--content {
		background-color: $brand-primary-light;
		padding: 40px;
		margin: 0;
		position: relative;
		z-index: 9999;
	}

	.note--title {
		font-size: 28px;
		display: inline;
	}
	.note--text {
		margin-top: 20px;
		font-size: 21px;
	}

	position: relative;
	&:before {
		content:"";
		background: transparent url('../images/stripes-pattern-yellow.png') repeat;
		position: absolute;
		top: -20px;
		right: -20px;
		width: 60%;
		height: 40%;
		z-index: 0;
	}
}
/**
 * equal-height columns
 */
.equal-height {
	width: 100%;
	
	@include mq($from: tablet) {
		display: table;
	}
	>.equal-height-item {
		@include mq($from: tablet) {
			float: none;
			display: table-cell;
			vertical-align: top;
		}
	}
}

/* Hide screen reader content from sighted users while maintaining accessibility */
.screen-reader-response {
	clip: rect(1px, 1px, 1px, 1px); 
	height: 1px; 
	width: 1px; 
	overflow: hidden; 
	position: absolute !important;
}

/* Alert styles */

.alert-warning {
	background-color: $brand-white;
	color: $brand-text-color;
}
.alert-message {
	border-bottom: 1px solid $brand-grey;
	position: relative;
	padding-top: 20px;
	font-family: $headings-font-family;
	font-weight: bold;
	font-size: $font-size-base;

	@include mq($from: tablet) {
		font-size: $font-size-h3;
	}

	&:after {
		content:" ";
		width: 45%;
		height: 12px;
		background-color: $brand-primary;
		position: absolute;
		bottom: -13px;
		right: 0;

		@include mq($from: tablet) {
		  width: 210px;
		}
	}
}
