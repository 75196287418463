.toolBox {
	@extend .pull-right, .col-xs-12, .col-sm-7;
	display: inline;
  margin-top: 15px;
  height: auto;

  @include mq($from: mobile) {
		max-width: 440px;
  }
  @include mq($from: tablet) {
		display: inline-block;
  	margin-top: 0;
  }
  @include mq($from: desktop) {
		max-width: 450px;
  }
}

/* ToolBox Tabs (icon navigation between panels) */
.toolBox--nav-pills {
	@extend .row;

	> li {
		@extend .col-xs-2, .col-xs-offset-5;
		max-width: 60px;

		&:first-of-type {
			margin-left: 47%;

			@include mq($from: mobile) {
			  margin-left: 55%;
			}
			@include mq($from: tablet) {
			  margin-left: 58%;
			}
		}

	}
}
.toolBox--nav-pills a {
	width: 42px;
	height: 42px;
	background: $brand-white url('../images/icons-tabs.png') no-repeat;
	padding: 0;
	float: right;
}
/* ToolBox icon sprites for navs */
.toolBox--nav-pills .tabIcon--login {
	background-position: 0px -42px;
}
.toolBox--nav-pills .tabIcon--doc {
	background-position: -84px -42px;
}
.toolBox--nav-pills .tabIcon--search {
	background-position: -42px -42px;
}

.toolBox--nav-pills > li.active > a,
.toolBox--nav-pills > li.active > a:hover,
.toolBox--nav-pills > li.active > a:focus {
	background-color: $brand-white;
}
.toolBox--nav-pills > li.active .tabIcon--login {
	background-position: 0px 0px;
}
.toolBox--nav-pills > li.active .tabIcon--doc {
	background-position: -84px 0px;
}
.toolBox--nav-pills > li.active .tabIcon--search {
	background-position: -42px 0px;
}

.toolBox--nav-pills li:hover,
.toolBox--nav-pills li:focus,
.toolBox--nav-pills a:hover,
.toolBox--nav-pills a:focus {
	background-color: $brand-white;
}

/* ToolBox Panel content */
.toolBox--tab-content {
	position: relative;
	top: -5px;
	margin-top : 0;
}
.toolBox--tab-content .tab-pane {
	padding-bottom: 0;
	width: 95%;
	height: 55px;
	margin-top: 10px;
	position: relative;

	.close {
		color: $brand-text-color;
		position: absolute;
		right:  0;
		bottom: 10px;
		opacity: 1;
		font-size: 20px;
	}
	@include mq($from: tablet) {
	  width: 98%;
	}
}

.contactLink {
	border-top: 1px solid $brand-dark-grey;
	position: relative;
	width: 95%;
	a {
		color: $brand-text-color;
		text-transform: uppercase;
		font-weight: bold;
		font-size: 14px;
	}
	&:after {
		content:" ";
		width: 40%;
		height: 6px;
		background-color: $brand-primary;
		position: absolute;
		top: 0;
		right: 0;

		@include mq($from: desktop) {
		  // width: 53%;
		}
	}

	@include mq($from: tablet) {
	  width: 98%;
	}
}

/* ToolBox Type */

.tab-pane--title {
	font-size: 16px;
	margin-bottom: 0;
}
.tab-pane--text {
	font-size: 12px;
	max-width: 80%;
}

/* Search Form */

.tab-pane.tab-pane--search {
	padding-top: 5px;

	.nav-search-btn {
		border: none;
		background-color: inherit;
		position: relative;
	}
	.icon-search {
		font-size: 30px;
		color: $brand-light-beige;
		line-height: 1;
		position: absolute;
	}

	.nav-search-input {
		height: 35px;
	}

}
