// Icomoon configuration
// NOTE do not paste over this part
// --------------------
@font-face {
    font-family: 'icomoon';
    src:    url('#{$icon-font-path}icomoon.eot?9mgzbp');
    src:    url('#{$icon-font-path}icomoon.eot?9mgzbp#iefix') format('embedded-opentype'),
        url('#{$icon-font-path}icomoon.ttf?9mgzbp') format('truetype'),
        url('#{$icon-font-path}icomoon.woff?9mgzbp') format('woff'),
        url('#{$icon-font-path}icomoon.svg?9mgzbp#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-player:before {
    content: "\e90c";
}
.icon-search:before {
    content: "\e90b";
}
.icon-caret:before {
    content: "\e90a";
}
.icon-arrow:before {
    content: "\e909";
}
.icon-pencil:before {
    content: "\e908";
}
.icon-ome:before {
    content: "\e901";
}
.icon-risque:before {
    content: "\e902";
}
.icon-role:before {
    content: "\e903";
}
.icon-vie-fas:before {
    content: "\e905";
}
.icon-youtube:before {
    content: "\e907";
}
.icon-google-plus:before {
    content: "\e900";
}
.icon-facebook:before {
    content: "\e904";
}
.icon-twitter:before {
    content: "\e906";
}

