.blockCategory {
	background-color: $brand-beige;
	width: 100%;
	overflow-x: hidden;
	padding: 20px 0;

	.wrapper {
		@extend %container-custom;
	}

	.panel {
		background-color: transparent;
		box-shadow: none;
		margin-top: 0;
	}

	.block--title {
		color: $brand-accent;
		border-top: 1px solid $brand-grey;
		position: relative;
		padding-top: 20px;

		&:after {
			content:" ";
			width: 45%;
			height: 12px;
			background-color: $brand-primary;
			position: absolute;
			top: 0;
			left: 0;

			@include mq($from: tablet) {
			  width: 210px;
			}
		}
	}

	.panel-btn {
		display: block;
		text-align: center;
		button {
			margin: 0 auto;
		}
	}

	&:first-of-type {
		padding-top: 0;
	}
}

.blockCategory--header {
		border-left: 10px solid $brand-beige;
		border-right: 10px solid $brand-beige;
}



.blockCategory--items {
		display: block;
		height: 100%;

		ul {
			@extend .row;
			list-style: none;
			padding-left: 0;
			text-align: center;

			@include mq($from: tablet) {
				display: table;
			}
		}

		li {
			@extend .col-xs-12;
			padding: 0;
			background-color: $brand-beige;
			border: 10px solid $brand-beige;

			.excerpt {
				max-width: 380px;
				display: block;
				margin: 0 auto;
			} 
			@include mq($from: tablet) {
				width: calc(100%/3);
				background-color: $brand-white;
				float: none;
				display: table-cell;
				vertical-align: top;
			}
		}
}