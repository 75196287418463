
.single-dossier {
	
	.wrapper--content {
		position: relative;
	}
	.post-label {
		position: absolute;
		top: 170px;
		left: 47px;
		width: 154px;
		height: auto;
		margin: 24px 30px;
		z-index: 888;
	}
	.blockSinglePost{
		ul li:before {
			content: none;
		}
	}
}
.blockSinglePost {
	h3, h4 {
		font-size: 40px;
	}
}
.dossier--articleList {
	width: 100%;
	height: 100%;
	ul {
		list-style: none;
		padding-left: 0;
	}
	li {
		width: 100%;
		height: 100%;
		overflow: auto;
		border-top: 1px solid $brand-grey;
		position: static;
		padding-top: $grid-gutter-width;
	}

	li:first-of-type {
		border-top: none;
	}
	li:before {
		content: "";
	}
	.excerpt--title {
		margin-top: 0;
		padding: 0 10px;
	}
	.excerpt--text {
		margin-bottom: 0;
		padding: 10px;
	}
}

.dossier--heading {
	border-top: 1px solid $brand-dark-grey;
	position: relative;
	width: 100%;
	padding-top: 10px;

	&:after {
		content:" ";
		width: 42%;
		height: 6px;
		background-color: $brand-primary;
		position: absolute;
		top: 0;
		left: 0;

	}

}