/********************************
   Contact form container styles
 *********************************/
.blockContact {
	@extend %container-custom;
	max-width: 1100px;

	@include mq($from: bigphone) {
		margin-left: $grid-gutter-width;
		margin-right: $grid-gutter-width;
	}
	@include mq($from: tablet) {
	 	margin: 0 auto;
	}

	.page-header, .wpcf7 {
		@extend .row;
		margin-bottom: 0;
	}
	.block--title {
		@extend .col-xs-12;
	 	border-top: 1px solid $brand-grey; 
	 	padding-top: 15px;
	 	padding-left: 0;
	} 
	.block--title::before {
		width: 210px;
	  background-color: $brand-primary;
	  content: " ";
	  height: 12px;
	  left: 0;
	  position: absolute;
	  top: 0;
	}
}

/********************************
   Contact Form base styles
 *********************************/
.wpcf7-form {
	@extend .col-xs-12;
	overflow: hidden;

	fieldset {
		@extend .row;
		height: auto;
		background-color: $brand-white;
		margin-bottom: 0;
		padding: 0.5em 2*$grid-gutter-width 0;
	}
	.wpcf7-form-control-wrap { 
		@extend .row;
	}
	label, p.h3, input, .wpcf7-response-output, .wpcf7-response-output {
		font-family: $headings-font-family;
		font-size: $font-size-h3;
		color: $brand-accent;
		font-weight: 700px;
	}
	input {
		color: $brand-text-color;
		font-weight: 500px;
		box-shadow: none;
		border: 1px solid $brand-medium-grey;
	}
	input[type="submit"] {
		@extend .btn, .btn-primary;
		display: block;
		width: 310px;
		float: none;
		margin: 0 auto;
	}
	.contact-form-submit {/* row */
		padding-top: 1.35em;
		background-color: $brand-beige;
	}
	.contact-form-message-details, /* row */
	.contact-form-submit {/* row */
		padding-bottom: 1.35em; /* same as fieldset padding top */
	}
	.contact-form-select {/* containing row : content = select (see styling in section below) */
		position: relative;

		.arrow-down {
			position: absolute;
			top: 45%; 
			left: 85%;
			width: 0; 
		  height: 0; 
		  border-left: 12px solid transparent;
		  border-right: 12px solid transparent;
		  border-top: 16px solid $brand-primary;

	    @include mq($from: mobile, $until: tablet) {
				left: 89%;
	    }
		  @include mq($from: tablet) {
				left: 54%;
		  }
		}
	}
}

/********************************
   Contact Form grid adjustements
 *********************************/
.contact-form-sender-details,/* row */
.contact-form-subject-details {/* row */
	label {
		@extend .col-xs-12;
	}
	input, select {
		@extend .col-xs-12, .col-sm-7;
	}
}
.contact-form-message-details { /* row */
	.message {
		@extend .col-xs-12, .col-sm-7;
	}
	.radio-group {
		@extend .col-xs-12, .col-sm-5;
	}
	input, textarea {
		@extend .col-xs-12;
		max-height: 150px;
		box-shadow: none;
		border: 1px solid $brand-medium-grey;
	}
	.h3 {
		margin-top: 4rem;
		@include mq($from: desktop) {
			margin-left: 4rem;
		}
	}
}

/********************************
   Contact Form Radio Buttons
 *********************************/
.radio-get-email-copy  {
	.wpcf7-list-item {
		@extend .col-xs-6;
		max-width: 300px;
		@include mq($from: desktop) {
			padding-left: 2.5rem;
		}
	}
	label {
		@extend .btnRadio;
	 	margin-left: -30px;
	 	padding-left: 2.4em;
	 	@include mq($from: mobile, $until: tablet) {
	 		padding-left: 3em;
	 		margin-left: -10px;
	 	}
		@include mq($from: desktop) {
			padding-left: 3em;
			margin-left: -10px;
		}
	}
	label::before {
		left: 26px;
		top: -3px;
	}
	input {
		position: absolute;
		z-index: -1;
		opacity: 0;
	}
	.wpcf7-list-item-label::before {
		content: "";
		width: 24px;
		height: 24px;
		border-radius: 12px;
		position: absolute;
		background-color: transparent;
		left: 38.5px;
		top: 10px;
	}

	/* Hover & Focus state */
	label:hover input ~ .wpcf7-list-item-label::before,
	label input:focus ~ .wpcf7-list-item-label::before {
		opacity: 0.6;
	}
	/* Checked state */
	label input:checked ~ .wpcf7-list-item-label::before {
		background-color: $brand-primary;
	}
}


/********************************
   Contact Form select element
 *********************************/
.select-choice-asl {
	select {
		appearance: none;
		-webkit-appearance: none;
		-moz-appearance: none;
		box-shadow: none;
		color: $brand-text-color;
		font-weight: 500px;
		height: 50px;
		border: 1px solid $brand-medium-grey;
    background-color: transparent;
    z-index: 999;
	}
	select::-ms-expand {
		display: none;
	}
	option {
		color: $brand-text-color;
		font-weight: 500px;
		height: 50px;
	}
}


/********************************
   Contact Form response and error messages
 *********************************/
.wpcf7-response-output {
	width: 100%;
	padding: $grid-gutter-width;
	height: auto;
	text-align: center;
}
.wpcf7-not-valid-tip {
	padding-left: 1em;
	color: $brand-text-color;
	font-size: 0.8em;
}