
.nav-primary {
	@extend .navbar, .navbar-fixed-top;
  @extend %container-custom, %header-height;
  @include clearfix;
  height: auto;
  min-height: $navbar-brand-height;
  background-color: $brand-white;
  border: none;
  margin: 0 auto;
  padding:  10px 10px;
}


//== Brand logo and home button
.navbar-brand {
  @extend .pull-left, .col-xs-9, .col-sm-5;
  @extend %navbar-brand-height;
  margin-bottom: 0;
  padding: 12px 0 0 0;

  a {
    width: 100%;
    height: auto;
    a:hover, a:focus {
      text-decoration: none;
    }
  }

  img {
    height: auto;
    max-height: 100%;
    width: auto;
    max-width: 310px;

    @include mq($from: mobile) {
      max-width: 430px;
    }
    @include mq($from: wide) {
      width: 422px;
      max-width: 422px;
      max-height: 140px;
      padding: 0;
    }
  }
  @include mq($from: mobile) {
    padding: 7px 0 0 0;
  }
  @include mq($from: wide) {
    width: 560px;
    // max-height: 126px;
    padding: 0;
  }
}

//==   Toggle button for main menu (small devices only)
.navbar-toggle {
	@extend .col-xs-3;
  display: block;
  max-width: 65px;
  padding: 0 5px;
  margin: 0;
  border: none;
  border-radius: 0;
  text-transform: uppercase;
  &:focus,
  &:hover {
    background-color: $brand-white;
  }
  @include mq($from: mobile) {
    margin: 10px 0 0 0;
  }
  @include mq($from: tablet) {
    display: none;
  }
}

.navbar-toggle .icon-bar {
	position: static;
	float: none;
  border-radius: 3px;
  background-color: $brand-dark-grey;
  margin: 6px auto;
  height: 6px;
  width: 70%;
}

.menu-title {
	margin-bottom: 4px;
}


/**
 * Website primary navigation menu
 */
.navbar-collapse {
  @extend %container-custom;
  position: absolute;
  // top: $navbar-brand-height-sm;
  top: 100px;
  left: 0;
  width: 100%;
  height: auto;
  border: none;
  background-color: transparent;
  margin: 0 !important;
  padding: 0;
  text-align: left;

  @include mq($from: mobile) {
    top: 115px;
  }

  @include mq($from: tablet) {
    top: $navbar-brand-height-lg;
    text-align: center;
    padding-top: 0px;
    margin-top: 10px !important;
  }
  @include mq($from: desktop) {
    text-align: right;
  }
}

.navMenu {
	padding-left: 20%;
  padding-bottom: 20px;
	height: auto;
  background-color: $brand-white;

	li {
		list-style: none;
    height: auto;
		@include mq($from: tablet) {
		  display: inline;
		}
	}

  .link-role .icon- {
    @extend .icon-role;
  }

  .link-vie-fas .icon- {
    @extend .icon-vie-fas;
  }

  .link-risque .icon- {
    @extend .icon-risque;
  }

  .link-ome .icon- {
    @extend .icon-ome;
  }

	a {
		font-size: 16px;
		color: $brand-text-color;
		text-transform: uppercase;
		padding-left: 10px;

		@include mq($from: desktop) {
		  font-size: 18px;
			margin: 0 25px 0 0;
		}
    @include mq($from: wide) {
      font-size: 20px;
      margin: 0 35px 0 0;
    }
	}
  a:hover, a:focus {
    text-decoration: none;
  }

	[class^="icon-"] {
		color: $brand-accent;
		position: relative;
    padding-right: 10px;
	}
	@include mq($from: tablet) {
		display: inline;
		margin: 0 auto;
    padding-left: 0;
    padding-bottom: 0;
    padding-right: 20px;
	}
}

/**
 * Navigation icons + labels
 */
.icon-role:before {
	font-size: 31px;
}
.icon-risque:before {
	font-size: 26px;
	line-height: 2;
	position: relative;
	top: 2px;
}
.icon-ome:before,
.icon-vie-fas:before  {
	font-size: 32px;
	color: $brand-accent;
}
.icon-ome:before {
	position: relative;
	top:4px;
	left: 2px;
	line-height: 2;
}
.icon-vie-fas:before  {
	position: relative;
	top:6px;
	left: 2px;
	line-height: 1.6;
}

.icon-role + a {
	padding-left: 13px;
}
.icon-ome + a {
	padding-left: 15px;
}
.icon-vie-fas + a {
	padding-left: 13px;
}
