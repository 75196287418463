.single-post {
	&.wrapper {
		@extend %container-custom, %body-padding-top;
		max-width: $max-content-width;
		padding-left: 0;
		padding-right: 0;
	}
	.wrapper--content {
		max-width: $max-content-width;
		background-color: $brand-white;
		height: auto;
		overflow: auto;
		margin: 0 auto;
		padding: 30px 10px;

		@include mq($from: mobile) {
			padding: 30px 20px;
		}
		@include mq($from: desktop) {
			padding: 30px 50px;
		}
	}
	.tag {
		display: inline-block;
		background-color: $brand-primary;
		color: $brand-accent;
		width: auto;
		padding: 5px 10px;
		margin: 0 10px 20px;
		text-transform: uppercase;
	}

	.sidebar--content li:before {
		content: "> ";
		color: $brand-text-color;
		font-size: 20px;
		top: 7px;
	}
	h2, h3, h4, p, ul, img, a, blockquote {
		max-width: 100%;
	}
	p, a {
		max-width: 100%;
	}
	blockquote, ul {
		font-size: 18px;
	}
	.emph {
		font-weight: bold;
	}
	.block--title,
	.block-subtitle,
	h2, h3, h4 {
		color: $brand-accent;
	}
	.icon-arrow {
		color: $brand-primary;
		font-size: 32px;
		margin-top: 10px;
		line-height: 1.2;
	}
	.feedback--title {
		color: $brand-grey;
		display: inline-block;
		width: auto;
	}
	.feedback--subtitle {
		line-height: 1;
	}
	.block--title, .tags {
		@extend .col-xs-12, .col-sm-12;
		margin-bottom: 20px;
	}
	.block--title {
		margin-top: 0;
		color: $brand-accent;
	}
}

.blockSinglePost {
	@extend .col-xs-12, .col-sm-9;
	margin-top: 20px;

	blockquote {
		font-style: italic;
		border-left: none;
		padding: 0;
		margin-bottom: 16px;
	}
	img {
		padding-bottom: 20px
	}
	ul {
		list-style: none;
		padding-left: 30px;
	}
	ul li:before {
		content: "• ";
		color: $brand-primary;
		font-size:50px;
		line-height: 0.5;
		position: absolute;
		top: 0;
		left: -25px;
	}
	.carousel-inner li:before {
		content:none;
	}
	.asl-inner-higlight {
		 ul {
			li:before {
				color: $text-color;
			}
		}
	}
	li {
		position: relative;
	}
	a {
	  color: #0062b8;
	  text-decoration: underline;

	  &:hover,
	  &:focus {
	  text-decoration: none;
	  }
	}
	h3, h4 {
		font-size: 40px;
	}
	td, th {
	    padding: 10px 15px;
	    border: 1px #605b48 solid;
	}
	.mejs-container {
		background-color: #fff !important;
	}
	@include mq($from: tablet) {
	  padding-right: 20px;
	}
}
.blockSinglePost--feedback,
.blockSinglePost-note {
	@extend .col-xs-12, .col-sm-9;
}
.blockSinglePost--modified-date {
	margin-top: -40px;
	margin-bottom: 20px;
	font-size: 14px;
}

.blockSinglePost--feedback{
	padding-left: 0;
	padding-right: 0;
}

.main, .single-post.wrapper {
	padding-top: 270px;

	@include mq($from: tablet) {
		padding-top: 230px;
	}
	@include mq($from: desktop) {
		padding-top: 200px;
	}
}