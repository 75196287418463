// General
// NOTE styles on tag selectors
// ---------------------

body { 
    background-color: $brand-beige; 
    min-height: 750px;
    min-height: calc(100vh - 120px);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.main {
  @extend %body-padding-top;
  position: relative;
  max-width: 100%;
  overflow-x: hidden;
}

.block {
  display:block;
  overflow:auto;
}


// Bootstrap overrides
// NOTE supercharge bootstrap elements globally
// ---------------------
// .btn { font-style: italic; } (example)

.nav > li > a:hover,
.nav > li > a:focus  {
  background-color: transparent;
}

.navbar, .nav-primary, 
.navbar-fixed-top .navbar-collapse {
  height: $navbar-height-sm;
  min-height:  $navbar-height-sm;
  max-height:  $navbar-height-sm;

  @include mq($from: tablet) {
    height: $navbar-height-lg;
    min-height:  $navbar-height-lg;
    max-height:  $navbar-height-lg;
  }
}

.btn, 
.panel-heading, 
.panel-group .panel {
  border: none;
  border-radius: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;


  &:active, &:hover,  &:focus, &.active, &.focus,
  &:active:hover, &:active:hover,
  &.active:hover, &.active:focus,
  &.active.focus, &.focus:hover {
    box-shadow: none;
  }
}

.btn-primary {
  background-color: $brand-accent;
  color: $brand-white;

  &:active, &:hover,  &:focus, &.active, &.focus,
  &:active:hover, &:active:hover,
  &.active:hover, &.active:focus,
  &.active.focus, &.focus:hover {
    background-color: $brand-accent-light;
    box-shadow: none;
  }
}

.panel-default > .panel-heading + .panel-collapse > .panel-body {
  border-top: none;
}
// Utilities
// NOTE use `u-` prefix for public classes
// ---------------------
// .u-uppercase { text-transform: uppercase; } (example)
