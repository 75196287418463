.blockLogin {
	background-color: $brand-beige;
	width: 100%;
	overflow-x: hidden;
	padding: 50px 0 20px 0;

	.wrapper {
		border-left: 10px solid $brand-beige;
		border-right: 10px solid $brand-beige;
		@extend %container-custom;
	}

	.block--title {
		color: $brand-accent;
		border-top: 1px solid $brand-grey;
		position: relative;
		padding-top: 20px;
		padding-left: 0;
		font-weight: bold;
		font-size: 55px;
		margin-bottom: 10px !important;

		&:after {
			content:" ";
			width: 45%;
			height: 12px;
			background-color: $brand-primary;
			position: absolute;
			top: 0;
			left: 0;

			@include mq($from: tablet) {
			  width: 210px;
			}
		}
	}

	.wrapper-form {
		background-color: $brand-white;
		padding: 40px 25px;

		.field {
			margin-bottom: 30px;
			font-family: $headings-font-family;

			label {
				display: block;
				color: $brand-accent;
				font-size: 32px;
			}

			input {
				border: 1px solid #605b48;
				max-width: 610px;
				width: 100%;
				height: 45px;
				padding:10px;
				font-size: 25px;
			}
			input[type="password"] {
				font-family: $font-family-sans-serif;
			}
		}

		a, a:active, a:hover {
			font-family: $headings-font-family;
			color: $brand-grey;
			font-weight: bold;
		}
		

		ul {
			list-style:none;
			padding-left: 0;
			li {
				margin-bottom: 15px;
			}
			li:before {
			    color: #ffd500;
			    content: "► ";
			    font-size: 20px;
			    line-height: 0.5;
			}
		}
	}

	.btn {
		font-family: $headings-font-family;
		display: block;
	    background-color: #0062b8;
	    color: #ffffff;
	    padding: 9px 110px;
	    margin-left: auto;
	 	margin-right: auto;
	 	margin-top: 42px;
	 	font-weight: bold;
	 	text-transform: uppercase;
	}
}

.blockLogin--header {
		border-left: 10px solid $brand-beige;
		border-right: 10px solid $brand-beige;

		h3 {
			margin-bottom:0;
			font-size: 30px;
		}
		h3, p {
			font-family: $headings-font-family;
			color: $brand-grey !important;
		}
		p {
			font-size: 22px;
		}
}
