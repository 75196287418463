
.blockGuide {
	background-color: $brand-beige;
	padding: 70px 20px;
	text-align: center;

	.panel-collapse {
		background: #fff url('../images/stripes-pattern-yellow.png') repeat;
	}
	.btn-panel-next {
		width: 100%;
		margin-top: 25px;

		@include mq($from: bigphone) {
			max-width: $container-column-element;
		}
	}
}

.blockGuide--header {
  background-color: transparent;
  max-width: $container-block;
	border-top: 1px solid $brand-lighter-beige;
	position: relative;
	text-align: left;
	margin: 0 auto;
    padding-top: 20px;

	&:after{
		content:" ";
		width: 45%;
		height: 12px;
		background-color: $brand-primary;
		position: absolute;
		top: 0;
		left: 0;

		@include mq($from: tablet) {
		  width: 210px;
		}
	}

	.block--title {
		font-size: 36px;
		color: $brand-accent-medium;
		@include mq($from: tablet) {
			font-size: 42px;
		}
	}

	.btn-panel-next  {
		margin: 0 auto;
	}
}

.blockGuide--panel-group {
	max-width: $container-block;
	margin: 0 auto;
}

.blockGuide--panel  {
	background-color: transparent;
	padding-bottom: 35px;
	margin-top: 0;

	.panel-heading {
		background-color: $brand-white;
		text-align: left;
		padding: 30px;
	}
	.panel-title {
		font-size: 30px;
		background-color: $brand-white;
	}
	.panel-controls {
		padding-top: 30px;
	}
	.panel-body {
		text-align: left;
	}

	.panel-body--text {
		background-color: $brand-white;
		padding: 10px;
		max-width: $container-column-element;
		margin: 15px auto 20px;

		@include mq($from: bigphone) {
			max-width: 65.5%;
			margin-left: 0;
		}	
	}
}


.blockGuide--answers {
	@extend .row;
	background-color: transparent;
	list-style: none;
	padding-left: 0;

	li {
		text-align: center;
		background-color: transparent;
	}
}
.answers--item {
	@extend .col-xs-12, .col-sm-6, .col-md-4;	
	margin: 10px auto;
}