
.blockKeyDates {
	background-color: $brand-primary;
	width: 100%;
	height: auto;
	position: static;
	padding: 20px 0;

	.wrapper {
		@extend %container-custom;
	}

	.block--title {
		@extend .col-xs-12, .col-sm-4;
		font-family: $subheadings-font-family;
		text-transform: uppercase;
		font-size: 30px;
		padding-right: 130px;
	}

	.column {
		@extend .col-xs-12, .col-sm-8;
	}
}

.blockKeyDates--calendar {
	@extend .row;
	list-style: none;
	padding-left: 0;

	li {
		@extend .col-xs-12, .col-sm-6;
	}

}

.blockKeyDates--date {
 padding: 10px 0;
	a:hover {
		text-decoration: none;
	}
	.date {
		font-family: $subheadings-font-family;
		color: $brand-accent;
		font-size: 30px;
		font-weight: regular;
	}
	.text {
		font-size: 20px;
		margin-bottom: 0;
	}
}
