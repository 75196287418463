
//== Header specific styles
//
.headerMain { 
	@extend %header-height;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
  background-color: transparent;
  z-index: 99999;


  @include mq($from: tablet ) {
  	background-color: $brand-white;
  }
}
