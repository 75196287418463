
.blockSearch {
	.block--title {
		font-size: 34px;
	}
}
.search--header {
	@extend %container-custom;
}
.search--title {
	padding: 0 10px;
}

.search-query {
	font-style: italic;
}


