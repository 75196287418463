.blockArticle {
	background-color: $brand-beige;
	width: 100%;
	overflow-x: hidden;
	padding: 80px 0;

	.wrapper  {
		@extend %container-custom;
	}

	.excerpt {
		@extend .col-xs-12, .col-md-4;
		float: none;
		padding: 0;
		border: 10px solid $brand-beige;
		@include mq($from: tablet) {
			border: 20px solid $brand-beige;
			display: table-cell;
		}
	}
}

.blockArticle--row {
		display: inline;

		@include mq($from: tablet) {
			display: table;
		}
}

.asl-inner-higlight {
	background-color: $brand-primary;
	padding: 5px;
}

article > .asl-inner-higlight {
	padding: 10px;
	display: block;
	margin: 0 0 14px;
}
