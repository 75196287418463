// Common components
// NOTE components shared across multiple sections (ex: header, footer, nav…)
// --------------------


.sidebar {
	@extend .col-xs-12, .col-sm-3;
	padding: 0;
	margin-top: 20px;
	max-width: 285px;

	.sidebar--content {
		width: 100%;
		height: auto;
		background-color: $brand-primary;
		padding: 20px;
	}
	.sidebar--title {
		color: $brand-accent;
		text-transform: uppercase;
		border-bottom: 2px solid $brand-white;
		position: relative;
		padding-top: 95px;
		padding-bottom: 20px;
		text-align: center;
		font-size: 30px;
		margin: 0 auto;
	}
	.sidebar--action {
		margin: 0 auto;
		width: 154px;
		height: auto;
		background-color: $brand-white;
		padding: 30px 0;
		text-align: center;
		display: table;
	}
	ul {
		list-style: none;
		padding-left: 0;
		margin-top: 20px;
	}
	li {
		position: relative;
	}
	a {
		color: $brand-text-color;
	}
	.icon-pencil {
		background-color:$brand-white;
		border-radius: 90px;
		font-size: 122px;
		height: 180px;
		left: 0;
		line-height: 1;
		padding: 24px 30px;
		position: relative;
		top: -50px;
		width: 180px;
	}
	.title--text {
		margin: 0 auto;
		display: block;
	}
}

.sidebarDossier .title--text {
	padding: 0 30px;
}