/***********************************
**** Home Carousel
************************************/

.blockCarousel {
	background: none;
	@include mq($from: tablet) {
		background: url('../images/stripes-pattern-blue.png') repeat;
	}
}

.carousel--wrapper {
	display: block;
	width: 100%;
	padding: 10px 0;
	position: static;
	left: 0;
	overflow: hidden;
  top: ($navbar-height-sm + 30px);

  @include mq($from: tablet) {
    top: ($navbar-brand-height-lg + $navbar-height-lg);
	  padding: 0;
	}


	&:before {
		content: "";
		background: url('../images/stripes-pattern-blue.png') repeat;
		position: absolute;
		top: -15%;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 28%;
		overflow: hidden;

		@include mq($from: mobile, $until: bigphone) {
			height: 28%;
		}
		@include mq($from: bigphone, $until: desktop) {
			top: -34%;
			height: 50%;
		}
		@include mq($from: desktop) {
	   	background: none;
		}
	}
}

.homeCarousel {
	@extend %container-custom;
	padding: 0;
	margin: 0;
	position: relative;

	@include mq($from: desktop) {
	  margin: 0 auto;
	}
}

/**
 * Carousel Navigaiton
 */
.homeCarousel .carousel-control {
	text-shadow: none;
	background: transparent;
	z-index: 9999;
	opacity: 1;
}

.homeCarousel .right.carousel-control {
	display: none;
	right: -5%;

	.icon-caret {
		top: 40%;
		right: 50%;
		font-size: 90px;
		box-shadow: none;
		opacity: 1;
		position: absolute;

		@include mq($from: desktop) {
			right: 60%;
		}
		@include mq($from: wide) {
			right: 20%;
		}
	}
	@include mq($from: desktop) {
	 	display: block;
	}
}

.homeCarousel  .carousel-indicators {
  display: block;
	position: relative;
	top: 10px;
  left: 60%;
  margin-left: 0;

	li {
		background-color: $brand-light-beige;
		border: 1px solid $brand-light-beige;
		height: 12px;
		width: 12px;
		margin: 0;
	}
	li.active {
		background-color: $brand-accent-light;
		border: 1px solid $brand-accent-light;
	}

	@include mq($from: tablet) {
	  display: none;
	}
}

/**
 * Carousel Slides
 */
 .homeCarousel .item {
 	width: 100%;
 }
.homeCarousel .carousel-inner {
	overflow: visible;
}

.carouselItem--image {
	margin: 0 auto;
	width: 100%;
	height: auto;

	@include mq($from: desktop) {
		margin-left: -70px;
		width: 60%;
		float: left;
	}
}

/**
 * Carousel content : div used for positioning
 */
.carouselItem--content {
	width: 100%;
	height: auto;

	@include mq($from: desktop) {
		width: 44%;
		margin-top: 30px;
		max-width: 400px;
		float: left;
	}
	@include mq($from: wide) {
		max-width: 550px;
	}
 }
.carouselItem--content:after {
	clear: left;
 }

/**
 * Carousel Slides - Title & Subtitle
 */
.homeCarousel .carousel-caption {
	position: absolute;
	text-align: left;
	padding-bottom: 0;
	left: 10%;
	top: 13%;

	a:hover {
		text-decoration: none;
	}

	@include mq($from: mobile) {
		top: 25%;
	}
	@include mq($from: bigphone) {
		top: 35%;
	}
	@include mq($from: tablet) {
		top: 50%;
	}
	@include mq($from: desktop) {
		padding: 0;
		position: static;
		margin-left: -20%;
		max-width: 400px;
	}
	@include mq($from: wide) {
		max-width: 550px;
	}
}
.carouselItem--title,
.carouselItem--text,
.carouselItem--link {
	display: inline-block;
	text-shadow: none;
	height: auto;
	width: auto;
	margin-bottom: 0;
	padding: 15px;
	padding-bottom: 30px;
}
.carouselItem--title {
	display: inline;
	color: $brand-white;
	font-size: 20px;
	font-weight: bold;
	background: $brand-accent-light;
	line-height: 1.5;
	padding: 0;

	.highlight {
		display: inline;
		background: $brand-accent-light;
		padding: 10px 20px;
		box-decoration-break: clone;
	}
	@include mq($from: bigphone) {
		font-size: 24px;
	}
	@include mq($from: desktop) {
		font-size: 25px;
	}
	@include mq($from: wide) {
		font-size: 32px;
	}
}
/**
 * Carousel Slides - Details (text / excerpt)
 */
.carouselItem--details {
	background: $brand-white;
	position: static;
	margin: 0;
	padding: 20px;

	@include mq($from: desktop) {
		width: 95%;
		margin-top: 4px;
	}

	& > a:hover {
		text-decoration: none;
	}

	p {
		color: $brand-text-color;
		font-size: 16px;
		padding: 0;
		margin-bottom: 0;
	}
}

.carouselItem--link {
	display: block;
	padding: 0 10px 10px 20px;
	margin-top: 20px;

	@include mq($from: wide) {
		padding: 0 20px 20px 20px;
	}
}
.carouselItem--link a {
	color: $brand-accent-light;
	font-weight: bold;
	text-transform: uppercase;
}

/***********************************
**** History Carousel
************************************/
.blockSinglePost .blockHistory ul {
	padding-left: 0;

	@include mq($from: desktop) {
		padding-left: 30px;
	}
}
.blockHistory {
	overflow: visible;
	padding: 0 0 $grid-gutter-width;
	background: $brand-white;
	min-height: 450px;

	@include mq($from: mobile) {
		min-height: 520px;
	}
	@include mq($from: desktop) {
		overflow: hidden;
		padding: 0;
	}
}
.carousel--history {
	background: $brand-white;
	width: 100%;
	padding: 40px 0;
	overflow: visible;

	@include mq($from: desktop) {
		// overflow: hidden;
	}
	ul {
		padding: 0;
		margin-bottom: 0;
		overflow: visible;
		background: $brand-white;

		@include mq($from: desktop) {
			// overflow: hidden;
		}
	}
	.item {
		overflow-y: visible;
	}
	.carousel-inner:before {
		content:"";
		position: absolute;
		bottom: -90%;
		top: auto;
		right: auto;
		width: 100%;
		height : 105%;
		left: auto;
		z-index: 0;
		background: url('../images/stripes-pattern-blue.png') repeat;

		@include mq($from: bigphone) {
			height : 90%;
			bottom:  -60%;
		}
		@include mq($from: tablet) {
			height : 90%;
			bottom:  -50%;
		}
		@include mq($from: desktop) {
			right: -40px;
			height :120%;
			bottom: -80px;
		}
	}
	img {
		position: relative;
		z-index: 999;
		top: 0;
		width: 100%;

		@include mq($from: desktop) {
			width: 75%;
			top: auto;
			max-width: 100%;
		}
	}
	.carousel-caption {
		left: 5%;
		right: 5%;
		top: 65%;
		background: $brand-white;
		text-align: left;
		z-index: 9999;
		text-shadow: none;
		height: auto;
		bottom: auto;
		padding: 0;

		@include mq($from: bigphone) {
			top: 74%;
		}
		@include mq($from: desktop) {
			padding: $grid-gutter-width;
			right: 20px;
			top: 65px;
			left: 60%;
		}
		p {
			position: static;
			font-size: 1rem;
			padding: 0 $grid-gutter-width;

			@include mq($from: bigphone) {
				font-size: 1.5rem;
			}
			@include mq($from: desktop) {
				padding: 0;
				font-size: 1.25rem;
			}
		}
	}
	.item-title {
		background-color: $brand-accent;
		top: 0;
		left: 0;
		position: relative;
		color: $brand-white;
		padding: 0.55em 1.2em;
		font-size: 1.75rem;
		font-family: $headings-font-family;
		text-transform: uppercase;
		font-weight: bold;
		margin-bottom: 0;
		margin-top: 0;

		@include mq($from: desktop) {
			position: absolute;
			left: 0;
			top: -3.5rem;
			font-size: 2.2rem;
		}
	}
	.item-subtitle {
		position: static;
		background: $brand-white;
		font-size: 1.25rem;
		color: $brand-accent;
		margin-top: 0.5em;
		padding: 0 $grid-gutter-width;

		@include mq($from: desktop) {
			padding: 0;
			font-size: 1.75rem;
		}
	}
	.carousel-control {
		background: $brand-accent;
		width: 40px;
		height: 40px;
		top: auto;
		bottom: -40%;
		opacity: 1;
		padding-top: 8px;
		z-index: 10000;

		@include mq($from: bigphone) {
			bottom: -33%;
		}
		@include mq($from: tablet) {
			bottom: -30%;
		}
		@include mq($from: desktop) {
			bottom: 0;
		}
	}
	.carousel-control.right {
		left: 55%;
		right: auto;
	}
	.carousel-control.left {
		right: 55%;
		left: auto;
	}
	.carousel-control.left {
		-moz-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    filter: FlipH;
    -ms-filter: "FlipH";
	}
}