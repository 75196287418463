
// Helpers
// NOTE name them `%selector` so they won't be public classes
// NOTE then use `@extend %selector;` to apply styles
// ---------------------
%antialiased { -webkit-font-smoothing: antialiased; }


/* Header & Navbar heights */
%header-height {
  height: $header-height-sm;

  @include mq($from: tablet) {
    height: $header-height-lg;
  }
}

/* Body padding top = header height */
%body-padding-top {
  padding-top: $header-height-sm;

  @include mq($from: tablet) {
    padding-top: $header-height-lg;
  }
}


%navbar-brand-height {
  height: 100%;
  min-height: 1px;
  max-height: $navbar-brand-height-sm;

  @include mq($from: wide) {
    min-height: 1px;
    max-height: $navbar-height-lg;
  }
}

%container-custom {
    @extend .container-fluid;
    max-width: $container-desktop;

    @include mq($from: tablet) {
      margin-right: 20px;
      margin-left: 20px;
    }
    @include mq($from: wide) {
      margin: 0 auto;
    }
}

