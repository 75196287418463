.blockVideo {
	background: url('../images/stripes-pattern-blue.png') repeat;
	padding: 20px;
  @include mq($from: tablet) {
		padding: 50px;
	}
	.wrapper  {
		@extend %container-custom;
		padding: 30px 40px 40px;
		position: relative;
		background: linear-gradient(225deg, transparent 40px, $brand-white 0);

		.rounded {
			border-radius: 6px 0 6px 6px;
		}

		&:before {
		  content: "";
		  position: absolute;
		  top: -2px;
		  right: -2px;
		  border-width: 0 40px 40px 0;
		  border-style: solid;
		  border-color: $brand-primary transparent;
  		border-radius: 0 6px 0 0;
		}
		&.rounded:before {
		  // background: url('../images/stripes-pattern-blue.png') repeat;
			border-width: 30px;
		  border-color: transparent transparent $brand-primary $brand-primary;
		  border-radius: 0 0 0 5px;
		}
	}
	.row {
		margin-top: 20px;
	}
}
.blockVideo--wrapper {
	a:hover, a:active {
		color:$brand-accent;
	}
	@extend .col-xs-12, .col-sm-4;
}
.blockVideo--video {
	display: block;
	height: 100%;
	width: 74%;
	max-width: 380px;
	margin: 0 auto;
	position: relative;
  @include mq($from: tablet) {
		width: 100%;
	}

	.video--cover {
		position: absolute;
		width: 66px;
		height: 66px;
		border-radius: 33px;
		left: 40%;
		top: 20%;
		border: 3px solid $brand-white;
		background-color: $brand-medium-grey;
		opacity: 0.6;
	}
	.icon-player {
		position: absolute;
		left: 40%;
		top: 35%;
		color: $brand-white;
	}
	img {
		width: 100%;
		max-width: 380px;
		height: auto;
		max-height: 100%;
	}
	.video--title {
		font-size: 25px;
		color: $brand-accent;
		max-width: 100%;
	}
	.video--subtitle {
		font-size: 16px;
		max-width: 100%;
	}
}
