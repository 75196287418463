// Common components
// NOTE components shared across multiple sections (ex: social network buttons, breadcrumbs, etc)
// --------------------

//== Social Networks links
//
.socialNetworks{
	max-width: 180px;
	list-style: none;
	background-color: transparent;
	margin: 0 auto;
	padding-left: 0;
	padding-bottom: 20px;
	
	li {
		@extend .col-xs-4;
	}
	a {
		max-width: 100%;
	}
	[class^="icon-"], [class*=" icon-"] {
		color: $brand-white;
		font-size: 20px
	}
	@include mq($from: tablet) {
		margin: 0 0 0 auto;
	}
}

/**
 * Breadcrumbs
 */
.breadcrumb {
	background-color: transparent;
	max-width: $max-content-width;
	margin: 0 auto;
	padding: 10px;
	@include mq($from: mobile) {
		padding: 20px;
		padding-left: 60px;
	}
	a{
		white-space: nowrap;
	}
	a:hover, a:focus, a.active {
		color: $brand-text-color;
		text-decoration: none;
		opacity: 0.6;
	}
}

.filters {
	background-color: transparent;
	max-width: $max-content-width;
	margin: 0 auto;
	padding: 10px;
	text-transform: uppercase;
	font-size: 14px;
	@include mq($from: mobile) {
		padding: 0px 60px;
	}
	a{
		color: $brand-text-color-light;
		opacity: 0.6;
		margin-left: 10px;
	}
	a:hover, a:focus, a.active {
		color: $brand-text-color;
		text-decoration: none;
		opacity: 1;
		font-weight: bold;
	}
}