
//== Footer specific styles
//
//== Footer specific styles
//
.footerMain {
	background-color: $brand-grey;
	text-align: center;
	padding: 20px 0;
	overflow: hidden;

	@include mq($from: tablet) {
	 	text-align: right;
	}
	.wrapper {
		@extend %container-custom;

		padding-left: 20px;
		padding-right: 20px;
	}
}

.footerMain--links {
	border-top: 1px solid $brand-white;
	width: 100%;
	max-width: $container-block;
	margin: 0 auto;
	padding-top: 20px;

	ul {
		list-style: none;
		padding-left: 0;
		text-align: left;
		margin: 0;

		@include mq($from: tablet) {
			margin-left: -20px;
			margin-right: -20px;
		}
		li:first-of-type {
			@include mq($until: tablet) {
				padding-top: 20px;
			}
		}	
		li:last-of-type {
			@include mq($until: tablet) {
				padding-bottom: 20px;
			}
		}
		li {
			@extend .col-xs-12;
			@include mq($from: tablet) {
				padding-top: 0;
				padding-bottom: 0;
			}
		}

		a, li {
			color: $brand-white;
			font-size: 15px;
		}
	}
	.item-button {
		@include mq($from: tablet) {
			text-align: right;
			padding: 0;
		  float: right;
		}
		@include mq($until: tablet) {
			text-align: left;
		}
	}
	.btn-footer {
		border: 2px solid $brand-white-06;
		background-color: transparent;
		color: $brand-white;
	}
	.col-1 {
		@extend .col-sm-1;	
	}
	.col-2 {
		@extend .col-sm-2;	
		@include mq($from: tablet) {
			margin-left: 4%;
		}
	}
	.col-3 {
		@extend .col-sm-3;	
	}
	.wrapper {
		padding-right: 0;
	}
	.copyright {
		@include mq($from: tablet) {
			padding-left: 45px;
		}
	}
}
