
.excerpt {
	background-color: $brand-white;
	width: 100%;
	max-width: $container-column-element;
	margin: 0 auto;

	a {
		text-decoration: none;
	}
	img {
		width: 100%;
		max-width: $container-column-element;
		height: auto;
	}
}
.excerpt--title, 
.excerpt--text {
	color: $brand-text-color;
	text-align: left;
}
.excerpt--title {
	font-size: 24px;
	padding: 10px 10px 0;
	@include mq($from: tablet) {
		font-size: 29px;
		padding: 10px 30px 0;
	}
}
.excerpt--text {
	font-size: 16px;
	padding: 10px 10px 30px;
	@include mq($from: tablet) {
		font-size: 18px;
		padding: 10px 30px 30px;
	}
}

.excerpt-alt {
	width: 100%;
	max-width: 100%;
	display: block;

	img {
		width: 100%;
		max-width: 100%;


		@include mq($from: tablet) {
			float: left;
			width: 50%;
			max-width: 50%;
		}
	}
	img:after {
		clear: left;
	}
	.excerpt--content {
	
		width: 100%;
		max-width: 100%;
		padding-left: $grid-gutter-width;

		@include mq($from: tablet) {
			float: right;
			width: 50%;
			max-width: 50%;
		}
	}

	.excerpt--content:after {
		clear: right;
	}
}