.blockAction {
	@extend .row;
	padding: 40px;
	background-color: $brand-white;

	.wrapper {
		@extend %container-custom;
	}
}
.blockAction--action {
	@extend .col-xs-12, .col-md-4;
	position: relative;
	margin-top: 20px;
	margin-bottom: 20px;
	@include mq($from: tablet) {
		&:nth-of-type(2){
			border-left: 1px solid $brand-medium-grey;
			border-right: 1px solid $brand-medium-grey;
		}
	}

	a:hover {
		text-decoration: none;
	}
}

.action--title, 
.action--text,
.action--pictogram {
	text-align: center;
	color: $brand-accent;
}
.action--pictogram {
	height: auto;
}
.action--pictogram img {
	width: 30%;
	margin: 0 auto;
	&.picto-navette {
		max-width: 81px;
	}
	&.picto-guide {
		max-width: 62px;
	}
	&.picto-adhesion {
		max-width: 99px;
	}
}
.action--title {
	font-size: 36px;
	font-family: $headings-font-family;
	text-transform: uppercase;
}